import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import axiosModificado from 'components/ConfiguracionAxios';
import Select from 'react-select';
import Swal from 'sweetalert2';

function timeToSeconds(time) {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60;
}

function secondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return { hours, minutes };
}

function calculateTotalSeconds(start_time, end_time, rest_start, rest_end) {
  const startSeconds = timeToSeconds(start_time);
  const endSeconds = timeToSeconds(end_time);
  const restStartSeconds = timeToSeconds(rest_start);
  const restEndSeconds = timeToSeconds(rest_end);
  const alertMinus30Minutes = false;

  // Calculate the total working time in seconds
  let totalSeconds = endSeconds - startSeconds;

  // Calculate the rest period in seconds
  const restPeriodSeconds = restEndSeconds - restStartSeconds;

  // Convert 8 hours to seconds
  const eightHoursInSeconds = 8 * 3600;

  // If the total working time is more than 8 hours, check the rest period
  if (totalSeconds >= eightHoursInSeconds && restPeriodSeconds <= 1800) {
    totalSeconds += restPeriodSeconds; // Add the 30 minutes rest period to the total working time
    alertMinus30Minutes = true;
  }

  return { totalSeconds , alertMinus30Minutes };
}

function calculateDayNightHours(start_time, end_time, rest_start, rest_end) {
  const startSeconds = timeToSeconds(start_time);
  const endSeconds = timeToSeconds(end_time);
  const sixPmSeconds = timeToSeconds('18:00');
  
  // Calculate total working seconds with rules applied
  const {totalSeconds} = calculateTotalSeconds(start_time, end_time, rest_start, rest_end);
  
  let daySeconds = 0;
  let nightSeconds = 0;

  if (startSeconds < sixPmSeconds) {
    if (endSeconds <= sixPmSeconds) {
      // All work is done before 6 PM
      daySeconds = totalSeconds;
    } else {
      // Work spans before and after 6 PM
      daySeconds = sixPmSeconds - startSeconds;
      nightSeconds = totalSeconds - daySeconds;
      console.log("segundos noche: " + nightSeconds);
    }
  } else {
    // All work is done after 6 PM
    nightSeconds = totalSeconds;
  }

  const dayTime = secondsToTime(daySeconds);
  const nightTime = secondsToTime(nightSeconds);

  return { dayTime, nightTime, daySeconds, nightSeconds };
}

function CrearHorario() {
  const [formData, setFormData] = useState({
    rol: '',
    user_id: '',
    tienda_id: '',
    start_date: '',
    start_time: '',
    end_time: '',
    rest_start: '',
    rest_end: '',
    total_horas: 0,
    horas_diurnas: 0,
    horas_nocturnas: 0,
    estado: 0,
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionUsuario, setSelectedOptionUsuario] = useState(null);
  const [selectedOptionTienda, setSelectedOptionTienda] = useState(null);

  const [data, setData] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_usuario_for_select')
      .then( response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      }); 
  }, []);

  const [tiendas, setTiendas] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_tiendas_for_select')
      .then( response => {
        setTiendas(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      });
  }, []);
  const [roles, setRol] = useState([]);
  useEffect(() => {
      axiosModificado.get('/todos_rol_for_select')
      .then( response => {
        setRol(response.data);
      })
      .catch(error => {
        console.error('Error al obtener datos de la API:', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const eval44Boolean = async () => {
    const eval44 = await axiosModificado.get(`/obtener-horas/id/${formData.user_id}`)
    const eval44Segundos = await eval44.data.horas_trabajadas;
    console.log(eval44Segundos);
    return ( eval44Segundos > 158400 ) ? true : false ;
  }

  const enviarDatosCompletos = async ( tipo ) => {
    try {
      console.log("el tipo es: " + tipo);
      let {totalSeconds} = calculateTotalSeconds(formData.start_time, formData.end_time, formData.rest_start, formData.rest_end);
      let { dayTime, nightTime, daySeconds, nightSeconds } = calculateDayNightHours(formData.start_time, formData.end_time, formData.rest_start, formData.rest_end);


      if( tipo == 2 ){
        const restStartSecond = timeToSeconds( formData.rest_start );
        const restEndSecond = timeToSeconds( formData.rest_end );
        const sixPmSecondsToRest = timeToSeconds('18:00');
        if( restStartSecond < sixPmSecondsToRest ){
          daySeconds  = daySeconds - ( restEndSecond - restStartSecond );
        } else {
          nightSeconds  = nightSeconds - ( restEndSecond - restStartSecond );
        }
        totalSeconds = totalSeconds - ( restEndSecond - restStartSecond );
      }
      /* setFormData({ ...formData, total_horas: totalSeconds });
      setFormData({ ...formData, horas_diurnas: daySeconds });
      setFormData({ ...formData, horas_nocturnas: nightSeconds }); */
      console.log("segundos noches: " + nightSeconds + " en formato hora: " + nightTime.hours + ":" + nightTime.minutes);
      console.log("segundos dias: " + daySeconds + " en formato hora: " + dayTime.hours + ":" + dayTime.minutes);
      setFormData(prevState => ({
        ...prevState,
        total_horas: totalSeconds,
        horas_diurnas: daySeconds,
        horas_nocturnas: nightSeconds,
      }));
      console.table(formData)
      Swal.fire("llego","se guardaron los datos", "success");
      const resp = await axiosModificado.post( '/agregar_horario', { rol: formData.rol , user_id: formData.user_id , tienda_id: formData.tienda_id , start_date: formData.start_date , start_time: formData.start_time , end_time: formData.end_time , rest_start: formData.rest_start , rest_end: formData.rest_end , total_horas: totalSeconds, horas_diurnas: daySeconds,  horas_nocturnas: nightSeconds, estado: 0  } )
      console.table("******************");
      console.table(resp.data);
      //Swal.close();

    } catch (error) {
      Swal.fire("Error","Algo ha sucedido al intentar enviar los datos, por favor reviselos de nuevo.","error");
      console.error("=====================");
      console.log( error.message )
      console.error("=====================");
    }

  }

  const evalMismoHorario = async () => {
    try {
      const evalMismoH = await axiosModificado.post(`/verificar-horario`, { formData });
      console.log(evalMismoH.data);
      return evalMismoH.data;
    } catch (error) {
      
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if( !formData.start_date || !formData.start_time || !formData.end_time || !formData.rol || !formData.user_id || !formData.tienda_id ){
      Swal.fire("Error", "Ingrese todos los datos requeridos","error");
      return false;
    }
    const evalMH = await evalMismoHorario();
    console.log("****");
    console.log(evalMH);
    if(evalMH){
      Swal.fire("Error", "El usuario tiene mismo horario","error");
      return false;
    }

    if( !formData.rest_start || !formData.rest_end  ){
      Swal.fire({
        icon: "info",
        title: "Sin datos para descanso",
        text: "¿desea continuar sin horario descanso?",
        showCancelButton: true,
        confirmButtonText: "Continuar",
      }).then( async (result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Cargando...",
            icon: "info",
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
          });

          const tiemposDescanso = formData.start_time
          setFormData(prevState => ({
            ...prevState,
            rest_start: tiemposDescanso,
            rest_end: tiemposDescanso
          }));

          const eval44Segundos = await eval44Boolean();
          if (eval44Segundos) {
            Swal.fire({
              icon: "info",
              title: "mas de 44 horas",
              text: "El descanso es menos de 30 minutos y mas de 8hrs por lo que será considerado dentro de horario pagado",
              showCancelButton: true,
              confirmButtonText: "Continuar",
            }).then( () => {
              enviarDatosCompletos( 1 );
            });
          } else {
            enviarDatosCompletos( 1 );
            return false;
          }






        } else {
          return false;
        }
      });
    } else {
      // SI HAY datos de descanso
      console.log("ENTRO EN ESCENARIO DE SI HAY DATOS DE DESCANSO")
      console.log('Datos enviados:');
      console.table(formData);
      Swal.fire({
        title: "Cargando...",
        icon: "info",
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
            Swal.showLoading();
        },
      });
      
      const eval44Segundos = await eval44Boolean();
      const {alertMinus30Minutes} = calculateTotalSeconds(formData.start_time, formData.end_time, formData.rest_start, formData.rest_end);
      
      if (alertMinus30Minutes) {
        Swal.fire({
          icon: "info",
          title: "Descanso Considerado",
          text: "El descanso es menos de 30 minutos y mas de 8hrs por lo que será considerado dentro de horario pagado",
          showCancelButton: true,
          confirmButtonText: "Continuar",
        }).then( async (result) => {
          if (result.isConfirmed) {
            if (eval44Segundos) {
              Swal.fire({
                icon: "info",
                title: "Descanso Considerado",
                text: "El descanso es menos de 30 minutos y mas de 8hrs por lo que será considerado dentro de horario pagado",
                showCancelButton: true,
                confirmButtonText: "Continuar",
              }).then( () => {
                enviarDatosCompletos( 1 );
              });
            } else {
              return false;
            }
            return false;
          } else {
            return false;
          }
        });
      } else {
        console.log("B2")
        if (eval44Segundos) {
          console.log("B3")
          Swal.fire({
            icon: "warning",
            title: "Usuario tiene mas de 44hrs",
            text: "¿desea continuar?",
            showCancelButton: true,
            confirmButtonText: "Continuar",
          }).then( (result) => {
            if (result.isConfirmed) {
              enviarDatosCompletos( 2 );
              return false;
            } else {
              return false;
            }
          });
        } else {
          console.log("B4")
          enviarDatosCompletos( 2 );
          return false;
        }
      }
     
    }

    



    /* const start_date = formData.start_date;
    const end_time_miliseconds = new Date(start_date + "T" + formData.end_time);
    try {
      const durationInMilliseconds = new Date(start_date + "T" + formData.end_time) - new Date(start_date + "T" + formData.start_time);
      const durationInHours = durationInMilliseconds / (1000 * 60 * 60); // Convertir a horas
      console.log(durationInHours)
      if(durationInHours >= 8){
        if( formData.rest_start && formData.rest_end ){
          const restStart = new Date(start_date + "T" + formData.rest_start);
          const restEnd = new Date(start_date + "T" + formData.rest_end);
          const restDurationInMilliseconds = restEnd - restStart;
          const restDurationInMinutes = restDurationInMilliseconds / (1000 * 60); // Convertir a minutos
          console.log("duracion de descanso: " + restDurationInMinutes)
          if (restDurationInMinutes < 30) {
              // Realizar la validación necesaria
              console.log('El tiempo de descanso es menor a 30 minutos');
              Swal.fire('Cuidado', 'El tiempo de descanso es menor a 30 minutos' , 'warning');
          } else {
            console.log('El tiempo de descanso es mayor a 30 minutos');
            const response = await axiosModificado.post('/agregar_horario', formData);
            console.log('Respuesta del servidor:', response.data);
            Swal.fire('Guardado con exito', '' , 'success');
          }
        } else {
          Swal.fire('Ingrese datos en decanso', '' , 'warning');
        }
      } else {
        console.log('menos de 8hrs');
        Swal.fire('Turno menos de 8hrs', '' , 'warning');
      }  
    } catch (error) {
      console.error('Error al enviar datos en :', error);
      Swal.fire('Error en guardar', 'Intente de nuevo', 'error');
    } */
  };

  return (
    <>
      <div className="relative bg-lightBlue-600 md:pt-32 pb-32 pt-12">
        <div className='px-4 md:px-10 mx-auto w-full'>
          <div className='p-4 bg-white rounded'>
            <h6 className="text-blueGray-500 text-sm font-bold">Crear Horario PRUEBAS</h6>
            <form onSubmit={handleSubmit}>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Rol:
                </label>
                <Select options={roles} name='rol'  placeholder="Selecciona un Rol" onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                  console.log(selectedOption)
                  setFormData({ ...formData, rol: selectedOption ? selectedOption.value : '' });
                }}  />
              </div>
              
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Usuario:
                </label>
                <Select options={data} name='user_id' placeholder="Seleccione Usuario" onChange={(selectedOptionUsuario)=>{
                    setSelectedOptionUsuario(selectedOptionUsuario);
                    setFormData({ ...formData, user_id: selectedOptionUsuario ? selectedOptionUsuario.value : '' });
                }} />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Tienda:
                </label>
                <Select options={tiendas} name='tienda_id' placeholder="Seleccione Tienda" onChange={(selectedOptionTienda) =>{
                  setSelectedOptionTienda(selectedOptionTienda);
                  setFormData({ ...formData, tienda_id: selectedOptionTienda ? selectedOptionTienda.value : '' });
                }} />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Fecha:
                </label >
                <input type="date" name="start_date" value={formData.start_date} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Hora entrada:
                </label >
                <input type="time" name="start_time" value={formData.start_time} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Hora salida:
                </label >
                <input type="time" name="end_time" value={formData.end_time} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Descanso Inicio:
                </label >
                <input type="time" name="rest_start" value={formData.rest_start} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              <div className='relative w-full mb-3'>
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Descanso Fin:
                </label >
                <input type="time" name="rest_end" value={formData.rest_end} onChange={handleInputChange} className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" />
              </div>
              
              <button className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150" type="submit">Enviar</button>
            </form>
          </div>
        </div> 
      </div>
    </>
  );
}

export default CrearHorario;
